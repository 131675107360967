.meeting-form {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.spinner-wrapper {
		border-radius: 25px;
	}

	input {
		// border: 1px solid rgb(56, 72, 82);
		border-radius: 5px;
		position: relative;
		width: 100%;
		margin: 5px 0;
		line-height: 3.5ex;
		color: #afb6d2;
		padding: 5.5px;
		background-color: #1d233c !important;
		height: 100%;
	}

	input:focus-visible {
		outline: 2px solid #4aa5f0;
		background-color: white !important;
		color: black;
	}
}
