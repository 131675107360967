.button-class {
	background-color: #4aa5f0;
	color: white;
	// border: 1px solid rgb(56, 72, 82);
	border-radius: 5px;
	padding: 0.5rem;
	transform: scale(1);
	display: flex;
	text-align: center;
	font-weight: 600;
	justify-content: center;

	a {
		text-decoration: none;
		color: inherit;
	}
}

.button-class:hover {
	background-color: #d88730;
	color: white;
	// border: 1px solid white;
	cursor: pointer;
}

.button-class.absolute {
	position: absolute;
	top: 100%;
	z-index: 2;
	margin-top: 0.25rem;
	width: auto;
}

.button-class .danger {
	color: white;
	background-color: red;
	border: 1px solid red;
}

.button-class .danger:hover {
	color: red;
	background-color: white;
	border: 1px solid red;
}

.button-class.switch {
	border: none;
	text-decoration: underline;
	color: white;
	background-color: transparent;
	margin-top: 2rem;
	font-size: 1rem;
}

.button-class.disabled {
	cursor: not-allowed;
}

.button-class.big {
	padding: 1rem 2rem;
	font-size: 1.5rem;
	border-radius: 10px;
}
.button-class.switch.back {
	text-decoration: none;
	font-size: 0.9rem;
	color: #4aa5f0;
}
