.dashboardMain {
	position: relative;
	padding: 3rem;
	width: 100%;
	min-height: 100vh;
	box-sizing: border-box;
	overflow: hidden !important;
	.banner-right-img {
		position: absolute;
		top: -38%;
		left: 0;
		width: 200%;
		height: 250%;
		box-sizing: border-box;
		overflow: hidden !important;
		margin: 0;
		.star {
			animation: star 10s ease-out infinite;
		}
		/* Keyframes */
		@keyframes star {
			0% {
				opacity: 0;
				transform: scale(1.5) translateY(-0.75em);
			}
			20% {
				opacity: 1;
			}
			50% {
				opacity: 0.2;
				transform: scale(1.25);
			}
			89% {
				opacity: 0;
				transform: scale(1);
			}
			100% {
				opacity: 0;
				transform: translateZ(-1000em);
			}
		}
	}
	.dashboardHeader {
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	.dashboardContentWrapper {
		max-width: 900px;
		margin: auto;
		position: relative;
		h1 {
			color: white;
			margin-bottom: 2rem;
		}
		.meetingList {
			min-height: 200px;
			width: 100%;
			position: relative;
			gap: 1rem;
			display: flex;
			flex-direction: column;
		}
	}
}

.user-profile {
	display: flex;
	flex-direction: column;
}
