.terms-of-use-wrapper {
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	justify-content: center;
	width: 100%;
	height: 100%;
	align-items: center;
	background: #171d3486;
	z-index: 99;

	.terms-of-use-inner {
		.terms-of-use-title {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 2rem;
			display: flex;
			justify-content: space-between;
			padding: 3rem 1rem;
			align-items: center;
			.button-class {
				padding: 0.5rem 1rem;
			}
		}
		background-color: #eeeff6;
		color: black;
		position: relative;
		display: flex;
		flex-direction: column;
		max-width: 800px;
		max-height: 80vh;
		padding: 6.5rem 20px 2rem;
		text-align: left;
		border-radius: 20px;
		box-sizing: border-box;
		.terms-of-use-content {
			width: 100%;
			height: 100%;
			overflow: scroll;
		}
		h1 {
			font-size: "1.5rem";
		}
		p {
			font-size: 0.9rem;
		}
		.consent-input {
			margin-top: 20px;
			width: 100%;
			text-align: center;
			font-weight: bold;
			color: #171d34;
			label {
				input {
					margin-right: 0.9rem;
				}
			}
		}
	}
}
