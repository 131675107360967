body {
	margin: 0;
	font-family: "Titillium Web", sans-serif !important;
	font-weight: 400;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	min-width: 0 !important;
	background-color: #171d34;
}

#zmmtg-root {
	display: none;
	min-width: 0 !important;
}

iframe#webpack-dev-server-client-overlay {
	display: none !important;
}

input {
	border: 1px solid rgb(56, 72, 82);
	padding: 0.5rem;
	border-radius: 5px;
}

form {
	display: flex;
	gap: 2rem;
	div {
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}
}

.titillium-web-extralight {
	font-family: "Titillium Web", sans-serif;
	font-weight: 200;
	font-style: normal;
}

.titillium-web-light {
	font-family: "Titillium Web", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.titillium-web-regular {
	font-family: "Titillium Web", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.titillium-web-semibold {
	font-family: "Titillium Web", sans-serif;
	font-weight: 600;
	font-style: normal;
}

.titillium-web-bold {
	font-family: "Titillium Web", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.titillium-web-black {
	font-family: "Titillium Web", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.titillium-web-extralight-italic {
	font-family: "Titillium Web", sans-serif;
	font-weight: 200;
	font-style: italic;
}

.titillium-web-light-italic {
	font-family: "Titillium Web", sans-serif;
	font-weight: 300;
	font-style: italic;
}

.titillium-web-regular-italic {
	font-family: "Titillium Web", sans-serif;
	font-weight: 400;
	font-style: italic;
}

.titillium-web-semibold-italic {
	font-family: "Titillium Web", sans-serif;
	font-weight: 600;
	font-style: italic;
}

.titillium-web-bold-italic {
	font-family: "Titillium Web", sans-serif;
	font-weight: 700;
	font-style: italic;
}
