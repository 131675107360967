.student-dashboard {
	width: 100%;
	height: 100vh;
	display: flex;
	// flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8rem;
	gap: 5rem;
	box-sizing: border-box;
	color: white;
	overflow: hidden !important;
	position: relative;
	.banner-right-img {
		position: absolute;
		top: -38%;
		left: 0;
		width: 200%;
		height: 250%;
		box-sizing: border-box;
		overflow-x: hidden !important;
		margin: 0;
		.star {
			animation: star 10s ease-out infinite;
		}
		/* Keyframes */
		@keyframes star {
			0% {
				opacity: 0;
				transform: scale(1.5) translateY(-0.75em);
			}
			20% {
				opacity: 1;
			}
			50% {
				opacity: 0.2;
				transform: scale(1.25);
			}
			89% {
				opacity: 0;
				transform: scale(1);
			}
			100% {
				opacity: 0;
				transform: translateZ(-1000em);
			}
		}
	}
	.logo {
		width: 25rem;
	}

	.footer {
		position: absolute;
		bottom: 2rem;
		color: white;
		font-size: 1rem;
		font-weight: 700;
	}

	.button-class.switch {
		font-size: 0.9rem;
	}

	.student-form {
		max-width: 600px;
		min-width: 300px;
		width: 450px;
		padding: 50px;
		// border: 1px solid #5a73835b;
		border-radius: 5px;
		// box-shadow: 0 0 25px #5a73835b;
		display: flex;
		flex-direction: column;
		background-color: #1d233c;

		color: white;
		form {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			.input-wrapper {
				position: relative;
				.visibility-button {
					position: absolute;
					right: 12px;
					top: 12px;
					width: 24px;
					height: 24px;
					img {
						width: 24px;
						height: 24px;
					}
				}
			}

			input {
				// border: 1px solid rgb(56, 72, 82);
				border-radius: 5px;
				position: relative;
				width: 100%;
				margin: 5px 0;
				line-height: 3.5ex;
				color: #afb6d2;
				padding: 5.5px;
				background-color: #1d233c !important;
			}

			input:focus-visible {
				outline: 2px solid #4aa5f0;
				background-color: white !important;
			}
		}

		.loader {
			width: 50px;
			height: 28px;
			margin: auto;
			--_g: no-repeat radial-gradient(farthest-side, #384852 94%, #0000);
			background: var(--_g) 50% 0, var(--_g) 100% 0;
			background-size: 12px 12px;
			position: relative;
			animation: l23-0 1.5s linear infinite;
		}
		.loader:before {
			content: "";
			position: absolute;
			height: 12px;
			aspect-ratio: 1;
			border-radius: 50%;
			background: #f8f7f4;
			left: 0;
			top: 0;
			animation: l23-1 1.5s linear infinite,
				l23-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
		}
		@keyframes l23-0 {
			0%,
			31% {
				background-position: 50% 0, 100% 0;
			}
			33% {
				background-position: 50% 100%, 100% 0;
			}
			43%,
			64% {
				background-position: 50% 0, 100% 0;
			}
			66% {
				background-position: 50% 0, 100% 100%;
			}
			79% {
				background-position: 50% 0, 100% 0;
			}
			100% {
				transform: translateX(calc(-100% / 3));
			}
		}
		@keyframes l23-1 {
			100% {
				left: calc(100% + 7px);
			}
		}
		@keyframes l23-2 {
			100% {
				top: -0.1px;
			}
		}
	}

	.student-form h2 {
		text-align: center;
		margin-bottom: 20px;
	}
}
