.modal-button {
	padding: 1rem 2rem;
	font-size: 1rem;
	background-color: transparent;
	border: 1px solid white;
	color: black;
	border-radius: 25px;
	cursor: pointer;
	position: relative;
	transform: scale(1);
	transition: 200ms transform ease-in-out;
}

.modal-button:hover {
	background-color: rgba(255, 255, 255, 0.75);
	border: none;
	color: black;
	transform: scale(0.95);
	transition: 200ms transform ease-in-out;
}

.modal-wrapper {
	position: fixed;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.65);
	top: 0;
	left: 0;
}

.modal-inner {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 400px;
	width: 55%;
	padding: 1rem 1rem 5rem;
	gap: 3rem;
	background-color: #1d233c;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 25px;
	color: white;
}

.modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.25rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid gray;
	h3 {
		margin: 1rem auto;
		color: white;
	}
}

.modal-content {
	background-color: transparent !important;
	border: none !important;
}
