.meeting-card-wrapper {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	border: 1px solid #afb6d2;
	padding: 2rem 1rem;
	border-radius: 5px;
}

.meeting-information {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	text-align: left;
	.meeting-general-info {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		gap: 1rem;
	}

	.meeting-subinfo {
		display: flex;
		flex-direction: column;
		div {
			display: flex;
			gap: 1.25rem;
			position: relative;
			align-items: center;
			p {
				margin: 1rem 0;
			}
			.visibility-button {
				right: 12px;
				width: 24px;
				height: 24px;
				img {
					width: 18px;
					height: 18px;
				}
			}
		}
	}
}

.meeting-name {
	font-size: 1.25rem;
	font-weight: 700;
	color: #d88730;
}

.meeting-actions {
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	gap: 1rem;
	justify-content: space-evenly;
	align-items: center;
}
