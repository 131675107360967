.mainWrapper {
	display: flex;
	min-width: 500px;
	justify-content: center;
	align-items: center;
	height: 100vh !important;
	.user-status {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem;
}

.splitter .row {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5rem;
}
.main {
	height: 100vh;
	width: 100vw;
	max-height: 100vh;
	max-width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	overflow: hidden !important;
	gap: 3rem;
	position: relative;
	.banner-right-img {
		position: absolute;
		top: -38%;
		left: 0;
		width: 200%;
		height: 250%;
		box-sizing: border-box;
		overflow-x: hidden !important;
		margin: 0;
		.star {
			animation: star 10s ease-out infinite;
		}
		/* Keyframes */
		@keyframes star {
			0% {
				opacity: 0;
				transform: scale(1.5) translateY(-0.75em);
			}
			20% {
				opacity: 1;
			}
			50% {
				opacity: 0.2;
				transform: scale(1.25);
			}
			89% {
				opacity: 0;
				transform: scale(1);
			}
			100% {
				opacity: 0;
				transform: translateZ(-1000em);
			}
		}
	}

	.footer {
		position: absolute;
		bottom: 2rem;
		font-size: 1rem;
		font-weight: 700;
	}
	.logo {
		width: 25rem;
	}
}

.main:before {
	background: url(../../assets/header-left-circle-img.png);
	content: "";
	width: 445px;
	height: 445px;
	position: absolute;
	top: -180px;
	left: -240px;
}

@media screen and (max-width: 767px) {
	.main {
		flex-direction: column;
		min-width: 100%;
		gap: 1rem;
		.banner-right-img {
			display: none;
		}
		.mainWrapper {
			height: auto !important;
		}
		.logo {
			width: 10rem;
		}
	}
}
